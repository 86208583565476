<template>
  <div>
    <el-form
      :model="addTechProcessesForm"
      ref="formedForm"
      :rules="rules"
      label-position="top"
      label-width="120px"
    >
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item
            prop=""
            label="Наименование вида деятельности/технологического процесса"
          >
            <el-row :key="key" v-for="(objName,key) in addTechProcessesForm.listName">
              <el-col :span="8">
                <el-input v-model="objName.name"></el-input>
              </el-col>
              <el-col :span="8">
                <el-date-picker 
                  v-model="objName.dateFrom"
                  type="date"
                  placeholder = "Дата старта наименования"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-col>
              <el-col :span="8">
                <el-date-picker 
                  v-model="objName.dateTo"
                  type="date"
                  placeholder = "Дата конца наименования"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-button @click="addLine" type="primary" style="float: left" 
                  >Добавить</el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            prop="organizationInfo"
            label="Объект / Филиал / Подразделение / Участок"
          >
            <el-select
              style="width: 100%"
              clearable
              value-key="id"
              v-model="addTechProcessesForm.organizationInfo"
              filterable
              placeholder="Выберите"
            >
              <el-option
                v-for="item in organizationHierarchyByType"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-button @click="save" type="primary">Создать</el-button>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notification from "@/mixins/notification";

export default {
  props: ["action"],
  name: "techprocessesAdd",
  mixins: [notification],
  data() {
    return {
      addTechProcessesForm: {
        listName: [{
          name: "",
          datEd: null,
          dateTo: null
        }],
        path: "",
        processName: "",
      },
      rules: {
        processName: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        organizationInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      organizationHierarchyByType: "GETORGANIZATIHIERARCHYBYTYPE",
    }),
  },

  methods: {
    
    addLine(){
      console.log(this);
      this.addTechProcessesForm.listName.push({
        name: "",
        datEd: null,
        dateTo: null
      });
    },
    save() {
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          this.addTechProcessesForm.processName=this.addTechProcessesForm.listName[0].name;
          this.$store
            .dispatch("createTechProcess", this.addTechProcessesForm)
            .then(() => {
              this.notification("Успешно", "Тех. процесс добавлен", "success");
              this.$store.dispatch("setShowModal", null);
              this.$store.dispatch("getListAllTechProcesses", {
                page: this.action.page,
                size: this.action.size,
              });
            });
        }
      });
    },
  },

  mounted() {
    this.$store.dispatch("getOrganizationHierarchyByType", { type: 3 });
  },
};
</script>

<style scoped></style>
